import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './AddNewProduct.css'
const AddNewProduct = () => {
  const [formData, setFormData] = useState({
    item_name: '',
    category: '',
    shape_and_cut: '',
    weight: '',
    color: '',
    measurement: '',
    mount: '',
    natural_faces: '',
    xrays: '',
    created_faces: '',
    test: '',
    comments: '',
    description: '',
    prices: '',
    presiding_Deity:'',
    ruling_planet:'',
    beej_mantra:'',
    image: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      for (let key in formData) {
        formDataToSend.append(key, formData[key]);

        toast.success(
          `${formData.item_name}  added successfully!`,
          {
            position: "bottom-right", // Use string directly
            autoClose: 3000,
            hideProgressBar: true,
          }
        );
        

      }

      const response = await axios.post('https://api.rudra.shivaksha.com/api/create', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log(response.data);
      setFormData({
        item_name: '',
        category: '',
        shape_and_cut: '',
        weight: '',
        color: '',
        measurement: '',
        mount: '',
        natural_faces: '',
        xrays: '',
        created_faces: '',
        test: '',
        comments: '',
        description: '',
        prices: '',
        presiding_Deity:'',
        ruling_planet:'',
        beej_mantra:'',
        image: null
      });
    } catch (error) {
      console.error('Error creating product:', error);
    }
  };

  return (
    <div className="bg-slate-50 w-[95%] md:w-1/2 rounded-md p-4 mx-auto my-4 shadow-sm shadow-slate-500">
      <h1 className="text-2xl font-bold mb-5">Add New Product</h1>
      <form
        action=""
        className="grid grid-cols-1 md:grid-cols-2 gap-4"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <div className="grid grid-cols-1 gap-1">
          <label htmlFor="item_name">Item Name</label>
          <input
            type="text"
            id="item_name"
            placeholder="Enter Item Name"
            className="grid grid-cols-1 md:grid-cols-2 gap-4"
            required
            value={formData.item_name}
            onChange={(e) => setFormData({ ...formData, item_name: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="category">Category:</label>
          <select
            id="category"
            name="category"
            value={formData.category}
            onChange={handleChange}
            style={{
              width: '100%',
              border: '1px solid #ccc',
              borderRadius: '0.25rem',
              padding: '0.5rem 1rem',
              fontSize: '1rem',
              outline: 'none',
              transition: 'border-color 0.3s ease'
            }}
            required
          >
            <option value="">Select Category</option>
            <option value="Crystal">Crystal</option>
            <option value="Mala">Mala</option>
            <option value="Bracelet">Bracelet</option>
            <option value="Silver Bracelet Mala">Silver Bracelet Mala</option>
            <option value="Rudraksha Nepali Beads">Rudraksha Nepali Beads</option>
            <option value="Rudraksha Indonesian Beads">Rudraksha Indonesian Beads</option>
            <option value="Sfatic Murti">Sfatic Murti</option>
            <option value="Parad Murti">Parad Murti</option>
            <option value="Metal Murti">Metal Murti</option>

          </select>
        </div>
        
        <div  className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className=" grid grid-cols-1 gap-1">
            <label htmlFor="shape_and_cut">Shape And Cut</label>
            <input
  type="text"
  id="shape_and_cut"
  name="shape_and_cut"
  placeholder="Enter Shape And Cut"
  className="w-full border-[1px] border-slate-500 rounded-md p-2"
  required
  value={formData.shape_and_cut} // Updated value attribute
  onChange={handleChange} // Updated onChange event handler
/>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <label htmlFor="weight">Weight</label>
            <input
  type="number"
  id="weight"
  name="weight"
  placeholder="Enter Weight"
  className="w-full border-[1px] border-slate-500 rounded-md p-2"
  required
  value={formData.weight} // Updated value attribute
  onChange={handleChange} // Updated onChange event handler
/>
          </div>
        </div>

        <div className=" grid lg:grid-cols-2 grid-cols-1 gap-4">
          <div className=" grid grid-cols-1 gap-1">
            <label htmlFor="color">Color</label>
            <input
  type="text"
  id="color"
  name="color"
  placeholder="Enter Color"
  className="w-full border-[1px] border-slate-500 rounded-md p-2"
  required
  value={formData.color} // Updated value attribute
  onChange={handleChange} // Updated onChange event handler
/>
          </div>
          <div className=" grid grid-cols-1 gap-1">
            <label htmlFor="measurement">Measurement</label>
            <input
  type="text"
  id="measurement"
  name="measurement"
  placeholder="Enter Measurement"
  className="w-full border-[1px] border-slate-500 rounded-md p-2"
  required
  value={formData.measurement} // Updated value attribute
  onChange={handleChange} // Updated onChange event handler
/>
          </div>
        </div>

        <div className=" grid lg:grid-cols-2 grid-cols-1 gap-4">
          <div className=" grid grid-cols-1 gap-1">
            <label htmlFor="mount">Mount</label>
            <input
  type="text"
  id="mount"
  name="mount"
  placeholder="Enter Mount"
  className="w-full border-[1px] border-slate-500 rounded-md p-2"
  required
  value={formData.mount} // Updated value attribute
  onChange={handleChange} // Updated onChange event handler
/>

          </div>
          <div className=" grid grid-cols-1 gap-1">
            <label htmlFor="natural_faces">Natural Faces</label>
            <input
  type="text"
  id="natural_faces"
  name="natural_faces"
  placeholder="Enter Natural Faces"
  className="w-full border-[1px] border-slate-500 rounded-md p-2"
  required
  value={formData.natural_faces} // Updated value attribute
  onChange={handleChange} // Updated onChange event handler
/>
          </div>
        </div>

        <div className=" grid lg:grid-cols-2 grid-cols-1 gap-4">
          <div className=" grid grid-cols-1 gap-1">
            <label htmlFor="xrays">Xrays</label>
            <input
  type="text"
  id="xrays"
  name="xrays"
  placeholder="Enter Xrays"
  className="w-full border-[1px] border-slate-500 rounded-md p-2"
  required
  value={formData.xrays} // Updated value attribute
  onChange={handleChange} // Updated onChange event handler
/>

          </div>
{/* Add input fields for presiding_Deity, ruling_planet, and beej_mantra */}
<div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
          <div className="grid grid-cols-1 gap-1">
            <label htmlFor="presiding_Deity">Presiding Deity</label>
            <input
              type="text"
              id="presiding_Deity"
              name="presiding_Deity"
              placeholder="Enter Presiding Deity"
              className="w-full border-[1px] border-slate-500 rounded-md p-2"
              value={formData.presiding_Deity}
              onChange={handleChange}
            />
          </div>
          <div className="grid grid-cols-1 gap-1">
            <label htmlFor="ruling_planet">Ruling Planet</label>
            <input
              type="text"
              id="ruling_planet"
              name="ruling_planet"
              placeholder="Enter Ruling Planet"
              className="w-full border-[1px] border-slate-500 rounded-md p-2"
              value={formData.ruling_planet}
              onChange={handleChange}
            />
          </div>
          <div className="grid grid-cols-1 gap-1">
            <label htmlFor="beej_mantra">Beej Mantra</label>
            <input
              type="text"
              id="beej_mantra"
              name="beej_mantra"
              placeholder="Enter Beej Mantra"
              className="w-full border-[1px] border-slate-500 rounded-md p-2"
              value={formData.beej_mantra}
              onChange={handleChange}
            />
          </div>
        </div>

          <div className=" grid grid-cols-1 gap-1">
            <label htmlFor="created_faces">Created Faces</label>
            <input
  type="text"
  id="created_faces"
  name="created_faces"
  placeholder="Enter Created Faces"
  className="w-full border-[1px] border-slate-500 rounded-md p-2"
  required
  value={formData.created_faces} // Updated value attribute
  onChange={handleChange} // Updated onChange event handler
/>
          </div>
        </div>

        <div className=" grid lg:grid-cols-2 grid-cols-1 gap-4">
          <div className=" grid grid-cols-1 gap-1">
            <label htmlFor="test">Test</label>
            <input
  type="text"
  id="test"
  name="test"
  placeholder="Enter Test"
  className="w-full border-[1px] border-slate-500 rounded-md p-2"
  required
  value={formData.test} // Updated value attribute
  onChange={handleChange} // Updated onChange event handler
/>
          </div>
          <div className=" grid grid-cols-1 gap-1">
            <label htmlFor="comments">Comments</label>
            <input
  type="text"
  id="comments"
  name="comments"
  placeholder="Enter Comments"
  className="w-full border-[1px] border-slate-500 rounded-md p-2"
  required
  value={formData.comments} // Updated value attribute
  onChange={handleChange} // Updated onChange event handler
/>
          </div>
        </div>

        <div className=" grid grid-cols-1 gap-1">
          <label htmlFor="description">Description</label>
          <textarea
  name="description"
  id="description"
  cols="30"
  rows="2"
  placeholder="Enter Product Description"
  className="w-full border-[1px] border-slate-500 rounded-md p-2"
  value={formData.description} // Updated value attribute
  onChange={handleChange} // Updated onChange event handler
></textarea>
        </div>

        <div className=" grid lg:grid-cols-2 grid-cols-1 gap-4">
          <div className=" grid grid-cols-1 gap-1">
            <label htmlFor="prices">Prices</label>
            <input
  type="number"
  id="prices"
  name="prices"
  placeholder="Enter Prices"
  className="w-full border-[1px] border-slate-500 rounded-md p-2"
  required
  value={formData.prices} // Updated value attribute
  onChange={handleChange} // Updated onChange event handler
/>
          </div>
        </div>
        
        <div>
          <label htmlFor="image">Image:</label>
          <input type="file" name="image" onChange={handleFileChange} />
        </div>
        <button
          className="bg-black text-white text-sm justify-self-end px-4 py-2 rounded-md float-right w-fit"
        >
          Add Product
        </button>
        <ToastContainer />
      </form>
    </div>
  );
};

export default AddNewProduct;
