import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProductList.css'; // Import your CSS file for dropdown styling
import { useNavigate } from 'react-router-dom';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const navigate = useNavigate(); // Use useNavigate() instead of history

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://api.rudra.shivaksha.com/api/products');
        setProducts(response.data.products || []);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    fetchProducts();
  }, []);

  const handleToggleDropdown = (productId) => {
    setOpenDropdownId(openDropdownId === productId ? null : productId);
  };

  const handleUpdateProduct = (productId) => {
    navigate(`/Product-Details/${productId}`); 
  };


  return (
    <div>
    <h2>All Products</h2>
      <div className="table-container">
      <table>
        <thead>
          <tr>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">S.N</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Item Name</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Shape and Cut</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Weight</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Color</th>
            <th className="text-xs font-medium text-gray-500 uppercase tracking-wider">Measurement</th>
            <th className="text-xs font-medium text-gray-500 uppercase tracking-wider">Mount</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Natural Faces</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">X-Rays</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Created Faces</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Test</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Comments</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Prices</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Presiding Deity</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Ruling Planet</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Beej Mantra</th>
            <th  className="text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={product.id}>
              <td>{index + 1}</td>
              <td>{product.item_name}</td>
              <td>{product.category}</td>
              <td>{product.shape_and_cut}</td>
              <td>{product.weight}</td>
              <td>{product.color}</td>
              <td>{product.measurement}</td>
              <td>{product.mount}</td>
              <td>{product.natural_faces}</td>
              <td>{product.xrays}</td>
              <td>{product.created_faces}</td>
              <td>{product.test}</td>
              <td>{product.comments}</td>
              <td>{product.description}</td>
              <td>{product.prices}</td>
              <td>{product.presiding_Deity}</td>
              <td>{product.ruling_planet}</td>
              <td>{product.beej_mantra}</td>
              <td>
                <div className="dropdown">
                  <button className="dropbtn" onClick={() => handleToggleDropdown(product.id)}>
                    Action
                  </button>
                  {openDropdownId === product.id && (
                    <div className="dropdown-content">
                      <a href="#" onClick={() => handleUpdateProduct(product.id)}>Update Product</a>
                      {/* Add more actions as needed */}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default ProductList;
