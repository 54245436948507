import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function ProductDetails() {
  const { productId } = useParams(); // Extract productId from URL
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://api.rudra.shivaksha.com/api/products');
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        const foundProduct = data.products.find(product => product.id === parseInt(productId));
        if (!foundProduct) {
          throw new Error('Product not found');
        }
        setProduct(foundProduct);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError(error.message);
        setLoading(false);
      }
    };
  
    fetchProducts();
  
    // Cleanup function
    return () => {
      // Cleanup logic if needed
    };
  }, [productId]);

  const handleSave = async () => {
    try {
      const {
        item_name,
        category,
        shape_and_cut,
        weight,
        color,
        measurement,
        mount,
        natural_faces,
        xrays,
        created_faces,
        comments,
        description,
        prices,
        presiding_deity,
        ruling_planet,
        beej_mantra
      } = product;

      await axios.put(`https://api.rudra.shivaksha.com/api/item-name/${productId}`, { item_name });
      await axios.put(`https://api.rudra.shivaksha.com/api/category/${productId}`, { category });
      await axios.put(`https://api.rudra.shivaksha.com/api/shape-and-cut/${productId}`, { shape_and_cut });
      await axios.put(`https://api.rudra.shivaksha.com/api/weight/${productId}`, { weight });
      await axios.put(`https://api.rudra.shivaksha.com/api/color/${productId}`, { color });
      await axios.put(`https://api.rudra.shivaksha.com/api/measurement/${productId}`, { measurement });
      await axios.put(`https://api.rudra.shivaksha.com/api/mount/${productId}`, { mount });
      await axios.put(`https://api.rudra.shivaksha.com/api/natural-faces/${productId}`, { natural_faces });
      await axios.put(`https://api.rudra.shivaksha.com/api/xrays/${productId}`, { xrays });
      await axios.put(`https://api.rudra.shivaksha.com/api/created-faces/${productId}`, { created_faces });
      await axios.put(`https://api.rudra.shivaksha.com/api/comments/${productId}`, { comments });
      await axios.put(`https://api.rudra.shivaksha.com/api/description/${productId}`, { description });
      await axios.put(`https://api.rudra.shivaksha.com/api/prices/${productId}`, { prices });
      await axios.put(`https://api.rudra.shivaksha.com/api/presiding-deity/${productId}`, { presiding_deity });
      await axios.put(`https://api.rudra.shivaksha.com/api/ruling-planet/${productId}`, { ruling_planet });
      await axios.put(`https://api.rudra.shivaksha.com/api/beej-mantra/${productId}`, { beej_mantra });

      console.log('Product details updated successfully!');
    } catch (error) {
      console.error('Error updating product details:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleChange = (field, value) => {
    setProduct(prevProduct => ({
      ...prevProduct,
      [field]: value
    }));
  };


  return (
    <div className="container mx-auto">
     <h2 className="text-2xl font-bold mb-4">Edit Product Details</h2>
     <div className="grid grid-cols-3 gap-4">
      <div>
        <label className="block mb-2">Item Name:</label>
        <input
          className="w-full border rounded-md p-2 focus:outline-none focus:ring focus:border-blue-300"
          type="text"
          value={product.item_name}
          onChange={(e) => handleChange('item_name', e.target.value)}
        />
      </div>
      <div>
          <label className="block mb-2">Category:</label>
          <input
            className="w-full border rounded-md p-2"
            type="text"
            value={product.category}
            onChange={(e) => handleChange('category', e.target.value)}
          />
        </div>
        <label>
          Shape and Cut:
          <input
            className="product-details-input"
            type="text"
            value={product.shape_and_cut}
            onChange={(e) => handleChange('shape_and_cut', e.target.value)}
          />
        </label>
        {/* Add more fields as needed */}
        <label>
          Weight:
          <input
            className="product-details-input"
            type="text"
            value={product.weight}
            onChange={(e) => handleChange('weight', e.target.value)}
          />
        </label>
        <label>
          Color:
          <input
            className="product-details-input"
            type="text"
            value={product.color}
            onChange={(e) => handleChange('color', e.target.value)}
          />
        </label>
        <label>
          Measurement:
          <input
            className="product-details-input"
            type="text"
            value={product.measurement}
            onChange={(e) => handleChange('measurement', e.target.value)}
          />
        </label>
        <label>
          Mount:
          <input
            className="product-details-input"
            type="text"
            value={product.mount}
            onChange={(e) => handleChange('mount', e.target.value)}
          />
        </label>
        <label>
          Natural Faces:
          <input
            className="product-details-input"
            type="text"
            value={product.natural_faces}
            onChange={(e) => handleChange('natural_faces', e.target.value)}
          />
        </label>
        <label>
          XRays:
          <input
            className="product-details-input"
            type="text"
            value={product.xrays}
            onChange={(e) => handleChange('xrays', e.target.value)}
          />
        </label>
        <label>
          Created Faces:
          <input
            className="product-details-input"
            type="text"
            value={product.created_faces}
            onChange={(e) => handleChange('created_faces', e.target.value)}
          />
        </label>
        <label>
          Comments:
          <input
            className="product-details-input"
            type="text"
            value={product.comments}
            onChange={(e) => handleChange('comments', e.target.value)}
          />
        </label>
        <label>
          Description:
          <input
            className="product-details-input"
            type="text"
            value={product.description}
            onChange={(e) => handleChange('description', e.target.value)}
          />
        </label>
        <label>
          Prices:
          <input
            className="product-details-input"
            type="text"
            value={product.prices}
            onChange={(e) => handleChange('prices', e.target.value)}
          />
        </label>
        <label>
          Presiding Deity:
          <input
            className="product-details-input"
            type="text"
            value={product.presiding_deity}
            onChange={(e) => handleChange('presiding_deity', e.target.value)}
          />
        </label>
        <label>
          Ruling Planet:
          <input
            className="product-details-input"
            type="text"
            value={product.ruling_planet}
            onChange={(e) => handleChange('ruling_planet', e.target.value)}
          />
        </label>
        <label>
          Beej Mantra:
          <input
            className="product-details-input"
            type="text"
            value={product.beej_mantra}
            onChange={(e) => handleChange('beej_mantra', e.target.value)}
          />
        </label>
        {/* Add more fields as needed */}
      </div>
      <button className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4" onClick={handleSave}>Save</button>
    </div>
  );
}

export default ProductDetails;