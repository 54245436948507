import React, { useState } from 'react';
import axios from 'axios';

const CustomerTestimonials = () => {
    const [formData, setFormData] = useState({
        customer_name: '',
        location: '',
        testimonial: '',
        image: null
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleImageChange = (e) => {
        setFormData({
            ...formData,
            image: e.target.files[0]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('customer_name', formData.customer_name);
        formDataToSend.append('location', formData.location);
        formDataToSend.append('testimonial', formData.testimonial);
        formDataToSend.append('image', formData.image);

        try {
            const response = await axios.post('https://api.rudra.shivaksha.com/api/post-customer-testimonials', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            setFormData({
                customer_name: '',
                location: '',
                testimonial: '',
                image: null
            });
        } catch (error) {
            console.error('Error posting customer testimonial:', error);
        }
    };

    return (
        <div style={{ maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto', paddingLeft: '1rem', paddingRight: '1rem' }}>
            <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>Customer Testimonials</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label style={{ marginBottom: '0.25rem' }}>Customer Name:</label>
                    <input
                        type="text"
                        name="customer_name"
                        value={formData.customer_name}
                        onChange={handleChange}
                        required
                        style={{ border: '1px solid #cbd5e0', borderRadius: '0.25rem', padding: '0.5rem 0.75rem', width: '100%' }}
                    />
                </div>
                <div>
                    <label style={{ marginBottom: '0.25rem' }}>Location:</label>
                    <input
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        required
                        style={{ border: '1px solid #cbd5e0', borderRadius: '0.25rem', padding: '0.5rem 0.75rem', width: '100%' }}
                    />
                </div>
                <div>
                    <label style={{ marginBottom: '0.25rem' }}>Testimonial:</label>
                    <textarea
                        name="testimonial"
                        value={formData.testimonial}
                        onChange={handleChange}
                        required
                        style={{ border: '1px solid #cbd5e0', borderRadius: '0.25rem', padding: '0.5rem 0.75rem', width: '100%', minHeight: '8rem' }}
                    ></textarea>
                </div>
                <div>
                    <label htmlFor="image">Image:</label>
                    <input type="file" name="image" onChange={handleImageChange} />
                </div>
                <button
                    type="submit"
                    style={{ backgroundColor: '#4299e1', color: 'white', padding: '0.5rem 0.75rem', borderRadius: '0.25rem' }}
                >
                    Submit
                </button>
            </form>
        </div>
    );
};

export default CustomerTestimonials;
