import React, { useState } from 'react';
import './PostYoutubeLink.css'; // Import your CSS file for styling

const PostYoutubeLink = () => {
  const [youtubeLinkText, setYoutubeLinkText] = useState('');

  const handleSubmit = async () => {
    try {
      // Send a POST request to your server with the YouTube link text
      const response = await fetch('https://api.rudra.shivaksha.com/api/youtubelinks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ text: youtubeLinkText }) // Sending the data in JSON format
      });

      // Check if the request was successful
      if (response.ok) {
        const data = await response.json();
        console.log(data.message); // Log the success message
        // Optionally, you can perform any additional actions here after successful posting
      } else {
        // If the request failed, log the error message
        const errorData = await response.json();
        console.error('Error:', errorData.error);
      }
    } catch (error) {
      console.error('Error:', error.message); // Log any unexpected errors
    }
  };

  return (
    <div className="container mx-auto px-4 mt-8">
      <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden shadow-lg">
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4">Please insert the YouTube link to fetch the data into Shivaksha website</h2>
          <input
            type="text"
            value={youtubeLinkText}
            onChange={(e) => setYoutubeLinkText(e.target.value)}
            placeholder="Enter YouTube link text"
            className="border border-gray-300 rounded px-4 py-2 w-full mb-4"
          />
          <button onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostYoutubeLink;
