import React, { useState } from 'react';
import Navigation from './Navigation';

function SubNav() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  
  return (
    <nav className="bg-gray-100 py-2 w-full">
     <Navigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar}
     style={{ 
        width: '100%',}} />
     <button 
        className="toggle-button" 
        style={{ 
          width: 'auto', // Set width to 100% to take up full container width
          marginLeft: '20%', // Apply inline CSS for left margin
          color: 'black', // Set text color to black
          border: '1px solid', // Add border
          backgroundColor: 'transparent', // Set background color to transparent
          cursor: 'pointer', // Change cursor to pointer on hover
          transition: 'margin 0.3s ease-in-out', // Add transition for margin change
          ':hover': {
            marginLeft: '22%', // Increase left margin on hover
          }
        }} 
        onClick={toggleSidebar}
      >
        &#9776; Menu
      </button>
    </nav>
  );
}

export default SubNav;
