import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UpdateCustomerTestimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [selectedTestimonial, setSelectedTestimonial] = useState('');
  const [newTestimonialText, setNewTestimonialText] = useState('');
  const [newTestimonialImage, setNewTestimonialImage] = useState(null);
  const [customerName, setCustomerName] = useState('');
  const [customerLocation, setCustomerLocation] = useState('');

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get('https://api.rudra.shivaksha.com/api/get-customer-testimonials');
      setTestimonials(response.data); // Assuming the response contains an array of testimonials
    } catch (error) {
      console.error('Error fetching testimonials:', error);
    }
  };

  const handleTestimonialChange = (event) => {
    setSelectedTestimonial(event.target.value);
  };

  const handleTextChange = (event) => {
    setNewTestimonialText(event.target.value);
  };

  const handleImageChange = (event) => {
    setNewTestimonialImage(event.target.files[0]);
  };

  const handleNameChange = (event) => {
    setCustomerName(event.target.value);
  };

  const handleLocationChange = (event) => {
    setCustomerLocation(event.target.value);
  };

  const handleUpdate = async () => {
  try {
    const formData = new FormData();
    formData.append('image', newTestimonialImage);
    formData.append('text', newTestimonialText);
    formData.append('customer_name', customerName);
    formData.append('location', customerLocation);
    formData.append('testimonial', newTestimonialText); // Change to 'testimonial'

    await axios.put(`https://api.rudra.shivaksha.com/api/update-customer-testimonials/${selectedTestimonial}`, formData);
    console.log('Testimonial updated successfully!');
  } catch (error) {
    console.error('Error updating testimonial:', error);
  }
};


  return (
    <div className="p-4 text-black">
      <h2 className="text-xl font-bold mb-4">Update Customer Testimonial</h2>
      <select onChange={handleTestimonialChange} className="border border-gray-300 rounded-md p-2 mb-4">
        <option value="">Select Testimonial to Update</option>
        {testimonials.map(testimonial => (
          <option key={testimonial.id} value={testimonial.id}>{testimonial.customer_name}</option>
        ))}
      </select>
      <div className="mb-4">
        <input type="text" placeholder="New Testimonial Text" value={newTestimonialText} onChange={handleTextChange} className="border border-gray-300 rounded-md p-2 w-full" />
      </div>
      <div className="mb-4">
        <input type="text" placeholder="Customer Name" value={customerName} onChange={handleNameChange} className="border border-gray-300 rounded-md p-2 w-full" />
      </div>
      <div className="mb-4">
        <input type="text" placeholder="Customer Location" value={customerLocation} onChange={handleLocationChange} className="border border-gray-300 rounded-md p-2 w-full" />
      </div>
      <div className="mb-4">
        <input type="file" onChange={handleImageChange} className="mb-2" />
      </div>
      <button onClick={handleUpdate} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Update Testimonial</button>
    </div>
  );
};

export default UpdateCustomerTestimonials;
