import React, { useState } from 'react';
import axios from 'axios';

const ProductUpdate = () => {
  const [productId, setProductId] = useState('');
  const [item_name, setItemName] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleProductUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append('image', image);
      formData.append('item_name', item_name);
      formData.append('category', category);

      await axios.put(`https://api.rudra.shivaksha.com/api/product-update/${productId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setProductId('');
      setItemName('');
      setCategory('');
      setImage(null);
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  return (
    <div>
      <h2>Update Product</h2>
      <div>
        <label>Product ID:</label>
        <input type="text" value={productId} onChange={(e) => setProductId(e.target.value)} />
      </div>
      <div>
        <label>Item Name:</label>
        <input type="text" value={item_name} onChange={(e) => setItemName(e.target.value)} />
      </div>
      <div>
        <label>Category:</label>
        <input type="text" value={category} onChange={(e) => setCategory(e.target.value)} />
      </div>
      <div>
        <label>Image:</label>
        <input type="file" onChange={handleImageChange} />
      </div>
      <button onClick={handleProductUpdate}>Update Product</button>
    </div>
  );
};

export default ProductUpdate;
