// PricesUpdate.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PricesUpdate.css';

const PricesUpdate = () => {
  const [products, setProducts] = useState([]);
  const [newPrices, setNewPrices] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const hideMessageAfterDelay = (setMessage) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
  };

  // Define fetchProducts function
  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://api.rudra.shivaksha.com/api/products');
      setProducts(response.data.products || []);
    } catch (error) {
      console.error('Error fetching products:', error);
      setErrorMessage('Failed to fetch products. Please try again later.');
      hideMessageAfterDelay(setErrorMessage);
    }
  };

  useEffect(() => {
    // Call fetchProducts function inside useEffect
    fetchProducts();
  }, []);

  const handlePriceChange = (productId, event) => {
    const { value } = event.target;
    setNewPrices(prevState => ({
      ...prevState,
      [productId]: value
    }));
  };

  const handleUpdatePrices = async (productId) => {
    try {
      // Send the updated price to the API endpoint
      await axios.put(`https://api.rudra.shivaksha.com/api/prices/${productId}`, {
        prices: newPrices[productId]
      });
      console.log(`Prices for product ${productId} updated successfully!`);
      // Update success message
      setSuccessMessage(`Prices for product ${productId} updated successfully!`);
      hideMessageAfterDelay(setSuccessMessage);
      // Clear the input field after successful update
      setNewPrices(prevState => ({
        ...prevState,
        [productId]: ''
      }));
      // Fetch the updated products data again
      fetchProducts();
    } catch (error) {
      console.error('Error updating prices:', error);
      // Update error message
      setErrorMessage(`Failed to update prices for product ${productId}. Please try again later.`);
      hideMessageAfterDelay(setErrorMessage);
    }
  };

  return (
    <div className='Container'>
      <h2>Update Prices</h2>
      {/* Success message */}
      {successMessage && <p className="success-message">{successMessage}</p>}
      {/* Error message */}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <table>
        <thead>
          <tr>
            <th>S.N</th>
            <th>SKU</th>
            <th>Item Name</th>
            <th>Old Prices</th>
            <th>Set New Prices</th>
            <th>Update</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={product.id}>
              <td>{index + 1}</td>
              <td>SKU {product.id}</td>
              <td>{product.item_name}</td>
              <td>{product.prices}</td>
              <td>
                <input
                  type="text"
                  value={newPrices[product.id] || ''}
                  onChange={(event) => handlePriceChange(product.id, event)}
                />
              </td>
              <td>
                <button onClick={() => handleUpdatePrices(product.id)}>
                  Update
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PricesUpdate;
