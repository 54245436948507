import React, { useState } from 'react';
import axios from 'axios';

const BlogPost = () => {
    const [formData, setFormData] = useState({
        title: '',
        content_EN: '',
        content_HI: '',
        image: null
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleImageChange = (e) => {
        setFormData({
            ...formData,
            image: e.target.files[0]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Get user object from localStorage
        const user = JSON.parse(localStorage.getItem("user"));

        // If user or username is null or empty, handle the error
        if (!user || !user.username) {
            console.error('Author value is missing');
            // Provide feedback to the user or handle the error condition accordingly
            return;
        }

        const author = user.username; // Get the username from the user object

        const formDataToSend = new FormData();
        formDataToSend.append('title', formData.title);
        formDataToSend.append('content_EN', formData.content_EN);
        formDataToSend.append('content_HI', formData.content_HI);
        formDataToSend.append('image', formData.image);
        formDataToSend.append('author', author); // Append the author value to the formData

        try {
            await axios.post('https://api.rudra.shivaksha.com/api/postblogpost', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // Handle successful blog post submission
            console.log('Blog post submitted successfully!');
        } catch (error) {
            // Handle error
            console.error('Error submitting blog post:', error);
        }
    };

    return (
        <div className="container mx-auto px-4">
            <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden shadow-lg">
                <div className="p-6">
                    <h2 className="text-2xl font-bold mb-4">Create a New Blog Post</h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="mb-4">
                            <label className="block mb-1">Title:</label>
                            <input
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                required
                                className="border border-gray-300 rounded px-4 py-2 w-full"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1">Content (English):</label>
                            <textarea
                                name="content_EN"
                                value={formData.content_EN}
                                onChange={handleChange}
                                required
                                className="border border-gray-300 rounded px-4 py-2 w-full h-32"
                            ></textarea>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1">Content (Hindi):</label>
                            <textarea
                                name="content_HI"
                                value={formData.content_HI}
                                onChange={handleChange}
                                required
                                className="border border-gray-300 rounded px-4 py-2 w-full h-32"
                            ></textarea>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="image" className="block mb-1">Image:</label>
                            <input type="file" name="image" onChange={handleImageChange} />
                        </div>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default BlogPost;
