import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import BlogPost from "./Component/PostFunction/BlogPost";
// import SidebarNavItem from "./Component/Dashboard/Navigation/Navigation";
import PostYoutubeLink from "./Component/PostFunction/PostYoutubeLink";
import AddNewProduct from "./Component/ProductFunction/AddNewProduct";
import ProductUpdate from "./Component/ProductFunction/ProductUpdate";
import PricesUpdate from "./Component/ProductFunction/PricesUpdate";
import ProductList from "./Component/ProductFunction/ProductList";
import ProductDetails from "./Component/ProductFunction/ProductDetailsUpdate";
import CustomerTestimonials from "./Component/PostFunction/CustomerTestimonials";
import Navigation from "./Component/Dashboard/Navigation/Navigation";
import Dashboard from "./Component/Dashboard/Dashboard";
import SubNav from "./Component/Dashboard/Navigation/SubNav";
import UpdateCustomerTestimonials from "./Component/PostFunction/UpdateCustomerTestimonials";
import MainContainer from "./Component/Home/MainContainer";


function App() {
  return (
    <div>

      <SubNav/>
      <Routes>
      {/* <Route path="/" element={<MainContainer/>} /> */}
      <Route path="/" element={<Dashboard/>} />
        <Route path="/BlogPost" element={<BlogPost />} />
        <Route path="/youtube-post" element={<PostYoutubeLink/>} />
        <Route path="/add-product" element={<AddNewProduct/>} />
        <Route path="/product-update" element={<ProductUpdate/>}/>
        <Route path="/prices-update" element={<PricesUpdate/>}/>
        <Route path="/Product-List" element={<ProductList/>}/>
        <Route path="/CustomerTestimonials" element={<CustomerTestimonials/>}/>
        <Route path="/UpdateCustomerTestimonials" element={<UpdateCustomerTestimonials/>}/>
        {/* Update Route for ProductDetails */}
        <Route path="/Product-Details/:productId" element={<ProductDetails />} />
      </Routes>
    </div>
  );
}

export default App;
