import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Navigation.css'; // Import the CSS file

const Navigation = ({ isSidebarOpen, toggleSidebar }) => {
  const handleToggleSidebar = () => {
    toggleSidebar(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    toggleSidebar(false); // Close the sidebar
  };

  const headerStyle = {
    fontSize: '24px', // Adjust font size as needed
    fontWeight: 'bold', // Adjust font weight as needed
    color: '#333', // Adjust text color as needed
    textAlign: 'center', // Align text to the center
    padding: '20px 0', // Add padding for spacing
    backgroundColor: '#f0f0f0', // Add background color
  };

  return (
    <div className={` ${isSidebarOpen ? 'sidebar-open' : ''}`}>
      <aside className="sidebar">
        <header className="header">
          <button 
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              color: '#333',
              cursor: 'pointer',
              fontSize: '18px',
              padding: '5px 10px',
              position: 'absolute',
              right: '10px',
              top: '10px'
            }} 
            onClick={handleCloseSidebar}
          >
            X
          </button>
          <Link to="/" className="logo-link">SHIVAKSHA</Link>
        </header>
        <nav className="sidebar-nav">
          <ul>
            <li>
              <Link to="/"><i className="ion-bag"></i> <span>Dashboard</span></Link>
            </li>
            <li>
              <Link to="/add-post"><i className="ion-ios-settings"></i> <span>POST DATA</span></Link>
              <ul className="nav-flyout">
                <li>
                  <Link to="/BlogPost"><i className="ion-ios-alarm-outline"></i>Post Blog</Link>
                </li>
                <li>
                  <Link to="/youtube-post"><i className="ion-ios-camera-outline"></i>Post Youtube Video</Link>
                </li>
                <li>
                  <Link to="/post-insta"><i className="ion-ios-chatboxes-outline"></i>Post Insta Video</Link>
                </li>
                <li>
                  <Link to="/post-facebook"><i className="ion-ios-cog-outline"></i>Post Facebook Post</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/add-post"><i className="ion-ios-settings"></i> <span>PRODUCT</span></Link>
              <ul className="nav-flyout">
                <li>
                  <Link to="/product-list"><i className="ion-ios-alarm-outline"></i>PRODUCT-LIST</Link>
                </li>
                <li>
                  <Link to="/add-product"><i className="ion-ios-camera-outline"></i>PRODUCT-CREATE</Link>
                </li>
                <li>
                  <Link to="/post-insta"><i className="ion-ios-chatboxes-outline"></i>PRODUCT-UPDATE</Link>
                </li>
                <li>
                  <Link to="/prices-update"><i className="ion-ios-cog-outline"></i>PRODUCT-PRICES</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/add-post"><i className="ion-ios-settings"></i> <span>ORDER</span></Link>
              <ul className="nav-flyout">
                <li>
                  <Link to="/BlogPost"><i className="ion-ios-alarm-outline"></i>INCOMING-ORDER</Link>
                </li>
                <li>
                  <Link to="/post-youtube"><i className="ion-ios-camera-outline"></i>RETURN REQUEST</Link>
                </li>
                <li>
                  <Link to="/post-insta"><i className="ion-ios-chatboxes-outline"></i>ORDER-STATUS</Link>
                </li>
                <li>
                  <Link to="/post-facebook"><i className="ion-ios-cog-outline"></i>ORDER-HISTORY</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/add-post"><i className="ion-ios-settings"></i> <span>TESTIMONIALS</span></Link>
              <ul className="nav-flyout">
                <li>
                  <Link to="/CustomerTestimonials"><i className="ion-ios-alarm-outline"></i>ADD-TESTIMONIALS</Link>
                </li>
                <li>
                  <Link to="/UpdateCustomerTestimonials"><i className="ion-ios-camera-outline"></i>TESTIMONIALS-UPDATE</Link>
                </li>
                <li>
                  <Link to="/post-insta"><i className="ion-ios-chatboxes-outline"></i>TESTIMONIALS-DELETE</Link>
                </li>
              
              </ul>
            </li>
            {/* Add other list items with Link components */}
          </ul>
        </nav>
      </aside>
    </div>
  );
}

export default Navigation;
