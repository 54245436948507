import React, { useState, useEffect } from 'react';

function Dashboard() {
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    // Fetch total products
    fetch('https://api.rudra.shivaksha.com/api/products')
      .then(response => response.json())
      .then(data => {
        // Assuming the API returns an array of products nested under the key "products"
        setTotalProducts(data.products.length);
      })
      .catch(error => {
        console.error('Error fetching product data:', error);
      });

    // Fetch total users
    fetch('https://api.rudra.shivaksha.com/api/users/Getusers')
      .then(response => response.json())
      .then(data => {
        // Assuming the API returns an array of users
        setTotalUsers(data.users.length);
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }, []); // Empty dependency array to run the effect only once after the initial render

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">Dashboard</h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Total Users</h2>
          <p className="text-gray-600">{totalUsers}</p>
        </div>
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Total Orders</h2>
          <p className="text-gray-600">500</p> {/* Placeholder for Total Orders */}
        </div>
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-lg font-semibold mb-2">Total Products</h2>
          <p className="text-gray-600">{totalProducts}</p>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
